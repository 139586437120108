import React from 'react'

import SectionTitle from './SectionTitle'

import styles from '../css-modules/contact.module.css'

export default () => (
  <div>
    <div className={styles.social}>
      <a href="/">
        <img src="/static/ISAPS-site-isaps-member-logo.png" />
      </a>
    </div>
    <SectionTitle content="Contact Us" />
    <div>Name:</div>
    <input />
    <div className={styles.subHeader}>Email:</div>
    <input />
    <div className={styles.subHeader}>Tel:</div>
    <input />
    <div className={styles.subHeader}>Your Message:</div>
    <input />
  </div>
)
