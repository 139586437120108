import React from 'react'

import Contact from '../components/Contact'
import Footer from '../components/Footer'

import '../layouts/index.css'
import styles from '../css-modules/contact.module.css'

const ContactPage = () => (
  <div className={styles.contact}>
    <Contact />
    <Footer />
  </div>
)

export default ContactPage
